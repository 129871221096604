.chip {
  border-radius: 6px;
  width: 100%;

  &.granted {
    background-color: #d7e7d3;
    color: #2c6c26;
    padding-left: 13px;
    .icon {
      color: #2c6c26;
    }
  }
  
  &.pending {
    background-color: #f1dfc6;
    color: #bd5601;
    padding-left: 13px;
    .icon {
      color: #bd5601;
    }
  }

  &.school {
    background-color: #F9F2FC;
    color: #C778E3;
    padding-left: 13px;
    .icon {
      color: #C778E3;
    }
  }

  &.unavailable {
    background-color: #e8defb;
    color: #7760B9;
    padding-left: 13px;
    .icon {
      color: #7760B9;
    }
  }

  &.formation {
    background-color: #FBF9ED;
    color: #DAC44E;
    padding-left: 13px;
    .icon {
      color: #DAC44E;
    }
  }

  &.bank {
    background-color: #dfdfdf;
    color: #747474;
    width: 100%;
    padding-left: 13px;
    height: 53px;
    border-radius: 0;
    .icon {
      color: #747474;
    }
  }
}

/// Demi journée ///
.chip.morning {
  clip-path: polygon(0 0, 100% 0, calc(100% - 30px) 100%, 0% 100%);
}


.chip.afternoon {
  clip-path: polygon(100% 100%, 100% 0, 30px 0, 0% 100%);

}

.chip.afternoonMorning {
  clip-path: polygon(calc(100% - 30px) 100%, 100% 0, 30px 0, 0% 100%);
}